import React from "react";
export const difference = [
  {
    text: "We are set apart by credibility, capability and confidentiality. Our founder, David Claypoole, has nearly 40 years of experience at the intersection of law and asset management and has developed a sterling reputation in the field – not only as a trusted advisor to asset management lawyers at every level, but also as a thought leader on industry trends. Our extensive network of top law firm partners, general counsels, and C-level HR directors gives us unique access to first-rate passive candidates who are not on the market, but open to the right opportunities. We only work with exceptional candidates from the most relevant firms and practices, which is why our placements stick.",
  },
  {
    text: "Our team closely follows industry news and regulatory developments. By focusing on our niche, we understand our clients’ organizational needs in a way that is uncommon among legal recruiters, offering unparalleled expertise in the organization of L&C departments, industry best practices and compensation structures. When you hire Claypoole to recruit at any level, from Legal Assistant to Chief Legal Officer, your message is being delivered by a team of discreet, knowledgeable, experienced professionals.",
  },
];

export const team = [
  {
    firstName: "David",
    lastName: "Claypoole",
    title: "Founder & President",
    description:
      "David Claypoole, Founder and President of Claypoole Search, handles all of our General Counsel, Chief Legal Officer, Associate General Counsel, and Chief Compliance Officer searches. With over 300 placements, he’s the industry’s trusted advisor on how financial and regulatory events affect legal department compensation and structure at asset managers. His opinions have been quoted in leading business publications, and his salary survey is indispensable to clients and candidates.",
    description2:
      'Prior to Claypoole, David founded Parks Legal Placement and held leadership positions with Cadwalader and Schulte. With nearly 40 years of personal contacts in the legal and investment management industries, he has a unique understanding of the hiring process from "both sides of the table." From introduction to negotiation, David saves clients and candidates time and headaches. David earned his B.A. in Economics from Vassar College.',
    image: "david",
  },
  {
    firstName: "Joe",
    lastName: "Chafkin",
    title: "Executive Recruiter",
    description:
      "Joe Chafkin is from Santa Barbara, California. He has experience placing lawyers and compliance professionals of all levels at leading alternative asset managers. Before joining Claypoole, he worked as the communications lead for a $7.4B public corporation, serving as a key legal and ESG touchpoint. Joe received his BA from Pomona College and his recruiter’s degree from David Claypoole University.",
    description2: "",
    image: "joe",
  },
  {
    firstName: "Helen",
    lastName: "Tan",
    title: "Chief Administrative Officer",
    description:
      "Helen Tan is located in Winston-Salem, North Carolina. She leads business administration and development for Claypoole, and previously handled operations for a $17B public eCommerce company, where she managed a team of 150+ direct reports. Helen received her MBA from University of Maryland’s Robert H. Smith School of Business and her BA from Scripps College.",
    description2: "",
    image: "helenTan",
  },
  {
    firstName: "Natalie",
    lastName: "Peña-Galvis",
    title: "Intern",
    description:
      "Natalie Peña-Galvis is a current undergraduate student at the University of North Carolina at Chapel Hill, where she double-majors in English and Advertising & Public Relations. She works as a copyeditor for The Daily Tar Heel and on the storytelling team for the 1893 Brand Studio. In her free time, Natalie enjoys reading and yoga.",
    description:
      "Natalie Peña-Galvis is a current undergraduate student at the University of North Carolina at Chapel Hill, where she double-majors in English and Advertising & Public Relations. She works as a copyeditor for The Daily Tar Heel and on the storytelling team for the 1893 Brand Studio. In her free time, Natalie enjoys reading and yoga.",
    image: "natalie",
  },
];

export const clients = [
  {
    context: [
      {
        title: "Funds",
        description:
          "Claypoole's clients are top alternative managers with assets under management ranging from one billion to trillions of dollars. Key service areas are:",
        list: [
          "Hedge funds",
          "Private equity funds",
          "Venture capital funds",
          "DeFi and crypto",
          "Tech funds",
          "Portfolio companies",
          "Family offices",
          "Funds-of-funds",
        ],
      },
    ],
    quote: [
      {
        text: "Building the legal departments at three of the world’s largest asset managers would not have been possible without the outstanding candidates David identified and presented to us for consideration. He not only found great candidates but appropriately matched them to the organizational culture and specialty needed.",
        author: "Howard Surloff",
        image: "howardSurloff",
        jobTitle: "Chief Legal Officer, Russell Investments",
      },
    ],
  },
  {
    context: [
      {
        title: "Strategies",
        description:
          "With the exception of family offices, offshore managers, and RICs, our clients are primarily registered investment advisors. We have experience recruiting across strategies, including:",
        list: [
          "Distressed credit and debt",
          "Global macro",
          "Quantitative",
          "Event-driven",
          "Merger arbitrage",
          "Structured products",
          "Activist",
          "Long-short equities",
          "Multi-strategy",
        ],
      },
    ],
    quote: [
      {
        text: "I have worked closely with David for over 20 years. As a candidate he successfully placed me at the firm of my choice.  As a client we worked together to build a global legal and compliance department for one of the premiere hedge funds. He has always been a trusted friend and sounding board.",
        author: "Jeff Blockinger",
        jobTitle: "Digital Asset Advisor, Former Hedge Fund CLO",
      },
    ],
  },
];

export const candidates = [
  {
    context: [
      {
        title: "law firms",
        description: "Placed candidates have trained at the following firms:",
        list: [
          "Cravath",
          "Debevoise",
          "Gibson Dunn",
          "Kirkland & Ellis",
          "Latham & Watkins",
          "Simpson",
          "Sullivan & Cromwell",
          "Watchtell",
        ],
      },
    ],
    quote: [
      {
        text: "When our long time General Counsel announced his retirement, we needed a replacement. David was an invaluable resource, producing exceptional candidates at multiple levels on both coasts and insightful advice. With David's help, we ended up hiring a star.",
        author: "Sharar Marwah",
        image: "shararMarwah",
        jobTitle: "Talent Partner, Venrock",
      },
    ],
  },
  {
    context: [
      {
        title: "Law Schools",
        description:
          "Placed candidates are alumni of the following law schools:",
        list: [
          "Columbia",
          "Georgetown",
          "Harvard",
          "NYU",
          "Stanford",
          "University of Michigan",
          "University of Pennsylvania",
          "Yale",
        ],
      },
    ],
    quote: [
      {
        text: "After five years at Simpson, I joined a multistrategy manager that became a casualty of the credit crisis. David saw what was coming and facilitated a great move to my first general counsel position well before things blew up.",
        author: "Heath Weisberg",
        image: "",
        jobTitle: "General Counsel, CAM Capital",
      },
    ],
  },
];

export const quotes2 = [
  {
    text: "David does not place talent unless the fit is absolutely right. He is more concerned with his reputation than with this or that placement. He understands that jobs change, but relationships and reputations endure; and David has a well-earned, sterling reputation in the investment management industry.",
    author: "Mike Pereira",
    title: "Attorney, Entrepreneur, Founder of Hedge Fund Law Report",
  },
  {
    text: "David has been recruiting fund lawyers longer than most managers. Over 20 years ago, before mandatory registration, when equity partnership at a top firm was the ring we chased, David placed me at Dechert which became the focal point of my career.",
    author: "George Mazin",
    title: "Former Practice Leader, Dechert LLP",
  },
];

export const graphs = [
  {
    title: "Legal Recruiters",
    image: "legalGraph",
  },
  {
    title: "Claypoole Search",
    image: "claypooleGraph",
    middle: true,
  },
  {
    title: "Fund Recruiters",
    image: "fundGraph",
  },
];

export const anchorIds = [
  "cs-hero",
  "cs-difference",
  "cs-david",
  "cs-timeline",
  "cs-team",
  "cs-clients",
  "cs-candidates",
  "cs-quote",
  "cs-footer",
];

