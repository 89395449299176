import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./timeline.scss";
import { timeline } from "../../data/components/timeline";
import { useCheckScreenSize } from "../../lib/checkScreenSize";
import Card from "./card";

gsap.registerPlugin(Draggable, InertiaPlugin, ScrollTrigger);

const Timeline = () => {
  const slider = useRef();
  const isTablet = useCheckScreenSize(1200);
  const [bounds, setBounds] = useState({ minX: 0, maxX: 0 });

  const addedOffset = isTablet ? 14 : 40;

  useEffect(() => {
    const panels = gsap.utils.toArray(".panel");
    const totalWidth = panels.reduce(
      (acc, panel) => acc + panel.offsetWidth + addedOffset,
      0
    );
    const calculatedBounds = {
      minX: -(totalWidth - window.innerWidth),
      maxX: 0,
    };
    setBounds(calculatedBounds);

    Draggable.create(slider.current, {
      type: "x",
      bounds: calculatedBounds,
      inertia: true,
      dragResistance: 0.5,
    });

    // Create a ScrollTrigger instance for the tease effect
    ScrollTrigger.create({
      trigger: slider.current,
      start: "top bottom",
      end: "bottom top",
      scrub: 1,
      onUpdate: (self) => {
        const xOffset = -gsap.utils.clamp(-80, 80, self.getVelocity() / 10);
        gsap.to(slider.current, {
          x: `+=${xOffset}`,
          ease: "none",
          overwrite: "auto",
        });
      },
    });

    return () => {
      Draggable.get(slider.current)?.kill();
      ScrollTrigger.getAll().forEach((st) => st.kill());
    };
  }, [isTablet]);

  // Function to move timeline left
  const moveLeft = () => {
    const currentX = gsap.getProperty(slider.current, "x");
    const newX = Math.min(currentX + 300, bounds.maxX);
    gsap.to(slider.current, { x: newX, ease: "none" });
  };

  // Function to move timeline right
  const moveRight = () => {
    const currentX = gsap.getProperty(slider.current, "x");
    const newX = Math.max(currentX - 300, bounds.minX);
    gsap.to(slider.current, { x: newX, ease: "none" });
  };

  return (
    <div className="timeline cursor-pointer">
      <div className="timeline__content" ref={slider}>
        {timeline.map((item, index) => (
          <div key={index} className="panel">
            <Card data={item} />
          </div>
        ))}
      </div>
      <div className="timeline__buttons">
        <button
          onClick={moveLeft}
          className="timeline__buttons__button cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 16 16"
          >
            <path
              fill="none"
              stroke="var(--white)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="m7.25 3.75l-4.5 4.5l4.5 4.5m6-4.5H2.75"
            />
          </svg>
        </button>
        <button
          onClick={moveRight}
          className="timeline__buttons__button cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 16 16"
          >
            <path
              fill="none"
              stroke="var(--white)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="m8.75 3.25l4.5 4.5l-4.5 4.5m-6-4.5h10.5"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Timeline;
