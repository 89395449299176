import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { StaticImage } from "gatsby-plugin-image";
import "./entry-page.scss";
import claypooleLogo from "../../images/common/entryLogo2.png";

const EntryPage = () => {
  const loadingContainerRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();

    tl.to(loadingContainerRef.current, {
      y: "-140%",
      duration: 3,
      delay: 3,
      ease: "power4.out",
    });
  }, []);

  return (
    <div className="entry-page" ref={loadingContainerRef}>
      <div className="entry-page__image">
        <StaticImage
          src="../../images/common/entryPage2.png"
          alt="Background Image"
          layout="fullWidth"
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          loading="eager"
        />
      </div>
      <div style={{ zIndex: 2 }} className="entry-page__logo">
        <div className="entry-page__logo__inner">
          <div className="entry-page__logo__image-wrapper">
            <img
              src={claypooleLogo}
              alt="claypoole logo"
              loading="eager"
            />
          </div>

          <span></span>
          <div className="entry-page__logo__text">
            <h2>Credible. </h2>
            <h2>Capable. </h2>
            <h2>Confidential. </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntryPage;
