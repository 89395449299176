export const timeline = [
  {
    year: "1984",
    event: "Graduation from Vassar, BA Economics",
    historical: false,
  },
  {
    year: "1986",
    event: "Joins Cadwalader as Case Manager",
    historical: false,
  },
  {
    year: "1987",
    event: "Manages EF Hutton Mortgage Litigation (first RMBS litigation)",
    historical: false,
  },
  {
    year: "1987",
    event: "Black Monday",
    historical: true,
  },
  {
    year: "1988",
    event: "Assists Robinhood Foundation in obtaining non-profit status",
    historical: false,
  },
  {
    year: "1989",
    event: "Manages SEC enforcement action on Hedge Fund Trading Violations",
    historical: false,
  },
  {
    year: "1990",
    event: "S&L Crisis",
    historical: true,
  },
  {
    year: "1994",
    event: "Bond Crash",
    historical: true,
  },
  {
    year: "1994",
    event: "Manages Bankers Trust Derivatives investigation",
    historical: false,
  },
  {
    year: "1995",
    event: "Becomes Director of Professional Development at Cadwalader",
    historical: false,
  },
  {
    year: "1995-97",
    event:
      "Recruits Structured Finance, Fund, M&A, Insolvency, and Derivatives attorneys",
    historical: false,
  },
  {
    year: "1997",
    event: "Joins Schulte as Director of Hiring",
    historical: false,
  },
  {
    year: "1997-99",
    event:
      "Recruits Funds, Structured Finance, Derivatives, M&A, Insolvency, and Securities Litigation attorneys",
    historical: false,
  },
  {
    year: "1997-99",
    event:
      "Establishes and runs the NY office of a national lateral legal recruiting firm",
    historical: false,
  },
  {
    year: "2000",
    event: "Tech Bubble Bursts",
    historical: true,
  },
  {
    year: "2001",
    event: "9/11",
    historical: true,
  },
  {
    year: "2002",
    event:
      "Establishes Parks Legal Placement as the first recruiting firm focused solely on Asset Management lawyers, placing first candidate at Seward & Kissel",
    historical: false,
  },
  {
    year: "2005",
    event: "First General Counsel placement",
    historical: false,
  },
  {
    year: "2005",
    event: "First Chief Compliance Officer placement",
    historical: false,
  },
  {
    year: "2006",
    event: "First Compensation Survey published ",
    historical: false,
  },
  {
    year: "2007",
    event: "First placement in Hong Kong",
    historical: false,
  },
  {
    year: "2007",
    event: "First press appearance ",
    historical: false,
  },
  {
    year: "2008",
    event: "GFC",
    historical: true,
  },
  {
    year: "2011",
    event: "First press appearance in Hedge Fund Law Report",
    historical: false,
  },
  {
    year: "2012",
    event: "First Placement in London",
    historical: false,
  },
  {
    year: "2013",
    event: "First Tax Director placement",
    historical: false,
  },
  {
    year: "2014",
    event: "Becomes regular speaker at GAIM Ops conferences",
    historical: false,
  },
  {
    year: "2017",
    event: "Rebrands Parks Legal Placement to Claypoole Executive Search ",
    historical: false,
  },
  {
    year: "2021",
    event: "First Cryptocurrency Manager placement ",
    historical: false,
  },
  {
    year: "2022",
    event: "Crypto Crash",
    historical: true,
  },
  {
    year: "2022",
    event:
      "Predicts impending law firm layoffs in Hedge Fund Law Report's second most read (2/500) article of the year",
    historical: false,
  },
  {
    year: "2023",
    event: "Inaugurates global affiliate network",
    historical: false,
  },
];
