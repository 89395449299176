import React from 'react';
import Quote from './quote';
import "../../styles/pages/homepage.scss";
import { v4 as uuidv4 } from "uuid";

const QuoteSection = ({ quotes }) => {
  return (
    <section id="cs-quote" className="cp-homepage__quotes">
      <div className="cp-homepage__quotes__inner">
        {quotes.map((item) => {
          return <Quote key={uuidv4()} data={item} />;
        })}
      </div>
    </section>
  );
}

export default QuoteSection
