import { useEffect, useRef, useState, useContext } from "react";
import ActiveSectionContext from "../context/activeSectionCtx";

const useActiveSection = (sections) => {
  const { activeSection, setActiveSection, shouldExecute, setShouldExecute } = useContext(ActiveSectionContext);
  const observer = useRef(null);
  const sectionRatiosRef = useRef({});

  const calculateThreshold = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      const sectionHeight = sectionElement.getBoundingClientRect().height;
      return 0.7 * (1 / sectionHeight);
    }
    return 0;
  };

  const largestRatio = (ratios) => {
    const firstKey = Object.keys(ratios)[0];
    return Object.entries(ratios).reduce((max, [key, value]) => {
      if (value > ratios[max]) {
        return key;
      } else {
        return max;
      }
    }, firstKey);
  };
  
  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          sectionRatiosRef.current[entry.target.id] = entry.intersectionRatio;
          const mostActiveSection = largestRatio(sectionRatiosRef.current);
          setActiveSection(mostActiveSection);
        });
      },
      {
        threshold: sections.map((sectionId) => calculateThreshold(sectionId)),
      }
    );

    sections.forEach((sectionId) => {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        observer.current.observe(sectionElement);
      }
    });

    return () => {
      if (observer.current) {
        sections.forEach((id) => {
          const sectionElement = document.getElementById(id);
          if (sectionElement) {
            observer.current.unobserve(sectionElement);
          }
        });
      }
    };
  }, [sections, setActiveSection, shouldExecute, activeSection]);
};

export default useActiveSection;
