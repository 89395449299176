import React from "react";
import PropTypes from "prop-types";
import "./team-card.scss";
import classNames from "classnames";
import ImageLoader from "../../lib/image-loader";
import { GatsbyImage } from "gatsby-plugin-image";

import placeHolder from "../../images/common/placeHolder.svg";
import placeHolderLG from "../../images/common/placeHolderLG.svg";

const TeamCard = ({ data, image, size = "medium", isAffiliate }) => {
  const placeHolderImg = size === "medium" ? placeHolder : placeHolderLG;
  const teamImage = image ? image : placeHolderImg;
  const cardClass = classNames("team-card", {
    "team-card--david": size === "large",
    "team-card": size === "medium",
  });

  const contentClass = classNames("team-card__content", {
    "team-card__content--david": size === "large",
    "team-card__content--affiliate": isAffiliate,
  });
  const { firstName, lastName, title, aspectRatio } = data;
  return (
    <div className={cardClass}>
      <div className="team-card__inner">
        <div className={contentClass}>
          <div className="team-card__image-wrapper">
            <div className="team-card__image">
              <GatsbyImage
                quality={90}
                width={486}
                image={teamImage}
                alt={`${firstName} ${lastName}`}
              />
            </div>
          </div>
          <div className="team-card__text">
            <div className="team-card__text__name">
              <h3>{firstName}</h3>
              <h3>{lastName}</h3>
            </div>
            {title && (
              <div className="team-card__founder">
                <span></span>
                <h4>{title}</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
