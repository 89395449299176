import React, { useState, useEffect, useContext } from "react";
import "./progress-bar.scss";
import classNames from "classnames";
import ActiveSectionContext from "../../context/activeSectionCtx";
import { anchorIds } from "../../data/pages/homepage";

const ProgressBar = ({ currentSection }) => {
  const [scroll, setScroll] = useState(0);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const { activeSection, setActiveSection } = useContext(ActiveSectionContext);

  const calculateScrollDistance = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const docHeight = getDocHeight();

    const totalDocScrollLength = docHeight - windowHeight;
    const scrollPosition = Math.floor((scrollTop / totalDocScrollLength) * 100);

    setScroll(scrollPosition);

    if (scrollTop + windowHeight >= docHeight - 400) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
    }
  };

  const getNextSection = () => {
    let index = anchorIds.findIndex((id) => id === activeSection);
    if (index === -1) throw new Error("Invalid active section");
    if (index === anchorIds.length - 1) return anchorIds[0];
    return anchorIds[index + 1];
  };

  const handleClick = () => {
    const newSectionId = getNextSection();

    const newSectionElement = document.getElementById(newSectionId);
    if (!isAtBottom) {
      if (newSectionElement) {
        window.scrollTo({
          top: newSectionElement.offsetTop,
          behavior: "smooth",
        });
      } else {
        console.log(`Element with id ${newSectionId} not found.`);
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
  };

  const getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", calculateScrollDistance);

    return function cleanup() {
      window.removeEventListener("scroll", calculateScrollDistance);
    };
  });

  const arrowClass = classNames("progress-arrow", {
    "progress-arrow--bottom": isAtBottom,
  });

  return (
    <div className="progress-bar cursor-pointer" onClick={() => handleClick()}>
      <svg className="progress-ring" width="100" height="100">
        <circle
          className="progress-ring__circle"
          stroke="#1F3A93"
          strokeWidth="4"
          fill="transparent"
          r="28"
          cx="50"
          cy="50"
          strokeDasharray="188"
          strokeDashoffset={`${188 - scroll * 1.8}`}
        />
        <g className={arrowClass}>
          <line
            x1="50"
            y1="42"
            x2="50"
            y2="62"
            stroke="#1F3A93"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <line
            x1="45"
            y1="57"
            x2="50"
            y2="62"
            stroke="#1F3A93"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <line
            x1="50"
            y1="62"
            x2="55"
            y2="57"
            stroke="#1F3A93"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </div>
  );
};

export default ProgressBar;
