import React from "react";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./client.scss";

const ClientCard = ({ data, image }) => {
  const isReversed = image ? false : true;
  const quoteColor = !isReversed ? "#1F3A93" : "#CDD1E4";

  // Ensure `image` is a Gatsby image data object (or null/undefined)
  const imageData = image && getImage(image);

  const { context, quote } = data;
  const { title, description, list } = context[0];
  const { text, author, jobTitle } = quote[0];
  const clientCardClasses = classNames("client-card", {
    "client-card--reverse": isReversed === true,
  });

  const quoteClasses = classNames("client-card__quote", {
    "client-card__quote--image": image,
  });

  return (
    <div className={clientCardClasses}>
      <div className="client-card__context">
        <h4>{title}</h4>
        <p>{description}</p>
        <ul>
          {list.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
      <div className={quoteClasses}>
        {image && (
          <div className="client-card__image">
            <GatsbyImage
              width={638}
              image={imageData}
              alt={""}
              className="client-card__quote__background"
            />
          </div>
        )}
        <div className="client-card__quote__inner">
          <svg
            width="42"
            height="31"
            viewBox="0 0 42 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 30.9801V21.7756C0.5 19.1619 1.01136 16.4915 2.03409 13.7642C3.05682 11.0369 4.40625 8.46591 6.08239 6.05114C7.75852 3.63636 9.5767 1.61932 11.5369 0L19.5483 4.73011C17.9574 7.23011 16.6506 9.84375 15.6278 12.571C14.6335 15.2983 14.1364 18.3381 14.1364 21.6903V30.9801H0.5ZM22.0199 30.9801V21.7756C22.0199 19.1619 22.5312 16.4915 23.554 13.7642C24.5767 11.0369 25.9261 8.46591 27.6023 6.05114C29.2784 3.63636 31.0966 1.61932 33.0568 0L41.0682 4.73011C39.4773 7.23011 38.1705 9.84375 37.1477 12.571C36.1534 15.2983 35.6562 18.3381 35.6562 21.6903V30.9801H22.0199Z"
              fill={quoteColor}
            />
          </svg>
          <p>{text}</p>
          <h5>{author}</h5>
          <h6>{jobTitle}</h6>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
