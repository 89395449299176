import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import "./card.scss";

const Card = ({ data }) => {
  const { year, event, historical } = data;
  const cardRef = useRef(null);
  const [isInView, setIsInView] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          } else {
            setIsInView(false);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  const eventClasses = classNames("timeline-card__content__event", {
    "timeline-card__content__event": historical === false,
    "timeline-card__content__event--historical": historical === true,
  });

  const svgClass = classNames("timeline-card__line__point", {
    "timeline-card__line__point--visible": isInView,
  });

  return (
    <div className="timeline-card" ref={cardRef}>
      <div className="timeline-card__line">
        <div className={svgClass}>
          <span>
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 4H4" stroke="#1F3A93" />
              <circle cx="7" cy="4" r="3.5" stroke="#1F3A93" />
              <path d="M10 4H14" stroke="#1F3A93" />
            </svg>
          </span>
        </div>
        <span></span>
      </div>
      <div className="timeline-card__content">
        <h4>{year}</h4>
        <p className={eventClasses}>{event}</p>
      </div>
    </div>
  );
};

export default Card;
