import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import "./graph.scss";

const Graph = ({ title, aspectRatio, image, middle = false, lowKey }) => {

  const graphClasses = classNames("cp-graph", {
    "cp-graph--middle": middle,
  });
  return (
    <div key={lowKey} className={graphClasses}>
      <GatsbyImage image={image} alt={title}/>
      <p>{title}</p>
    </div>
  )
};

export default Graph;
