import React, { useRef, useEffect } from "react";
import "../styles/pages/homepage.scss";
import { graphql } from "gatsby";
import { Graph } from "../components/graph";
import { assetArrayToObject } from "../lib/graphql-helpers";
import { StaticImage } from "gatsby-plugin-image";
import {
  difference,
  team,
  clients,
  candidates,
  quotes2,
  graphs,
  anchorIds,
} from "../data/pages/homepage.js";
import { TeamCard } from "../components/team-card";
import { Timeline } from "../components/Timeline";
import { ClientCard } from "../components/client";
import { EntryPage } from "../components/EntryPage";
import QuoteSection from "../components/quote/quote-section";
import { Link } from "../components/links";
import ProgressBar from "../components/progress-bar/progress-bar";
import useActiveSection from "../lib/useActiveSection";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Home = ({ data }) => {
  const {
    allFile: { edges: allImages },
  } = data;

  const david = team[0];
  const employees = team.slice(1);
  const heroImageRef = useRef(null);
  const teamImagesRef = useRef(null);
  const davidImageRef = useRef(null);
  const clientImagesRef = useRef(null);
  const candidatesImagesRef = useRef(null);

  const formattedImages = assetArrayToObject({
    useGatsbyImage: true,
    images: allImages,
  });

  useActiveSection(anchorIds);

  useEffect(() => {
    if (heroImageRef.current) {
      gsap.fromTo(
        heroImageRef.current,
        { y: -300 },
        {
          y: 0,
          scrollTrigger: {
            trigger: ".cp-homepage__hero",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }

    if (davidImageRef.current) {
      const davidSelector =
        davidImageRef.current.querySelector(".team-card__image");
      gsap.fromTo(
        davidSelector,
        { y: -100 },
        {
          y: 0,
          scrollTrigger: {
            trigger: davidSelector,
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }
    if (teamImagesRef.current) {
      const teamImageSelector =
        teamImagesRef.current.querySelectorAll(".team-card__image");
      gsap.fromTo(
        teamImageSelector,
        { y: -80, height: 400 },
        {
          height: 400,
          y: 0,
          scrollTrigger: {
            trigger: teamImageSelector,
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }
    if (clientImagesRef.current) {
      const clientImageSelector = clientImagesRef.current.querySelector(
        ".client-card__image"
      );
      gsap.fromTo(
        clientImageSelector,
        { y: -100 },
        {
          y: 0,
          scrollTrigger: {
            trigger: clientImageSelector,
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }
    if (candidatesImagesRef.current) {
      const candidatesImageSelector = candidatesImagesRef.current.querySelectorAll(
        ".client-card__image"
      );
      gsap.fromTo(
        candidatesImageSelector,
        { y: -100 },
        {
          y: 0,
          scrollTrigger: {
            trigger: candidatesImageSelector,
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <>
      <EntryPage
        image={formattedImages.entryPage2}
        logo={formattedImages.entryLogo}
      />
      <div className="cp-homepage">
        <section id="cs-hero" className="cp-homepage__hero">
          <div ref={heroImageRef} className="cp-homepage__hero__image-wrapper">
            <StaticImage
              src="../images/common/advisorsact.png"
              alt="hero background"
              layout="fullWidth"
              quality={80}
              formats={["AUTO", "WEBP", "AVIF"]}
              style={{ height: "100%", width: "100%" }}
              loading="eager"
            />
          </div>
          <div className="cp-homepage__hero__inner">
            <div className="cp-homepage__hero__text">
              <h1>L&C for RIAs.</h1>
              <h3>That's it.</h3>
            </div>
            <Link isAnchor to="/#cs-difference">
              <div className="cp-homepage__hero__arrow">
                <span>
                  <img src={formattedImages.downArrow} alt="downArrow" />
                </span>
              </div>
            </Link>
          </div>
        </section>
        <section id="cs-difference" className="cp-homepage__difference">
          <div className="cp-homepage__difference__inner">
            <div className="cp-homepage__difference__upper">
              <h3>The Claypoole difference</h3>
              <div className="cp-homepage__difference__text">
                {difference.map((item) => {
                  return <p key={item.text}>{item.text}</p>;
                })}
              </div>
            </div>
            <div className="cp-homepage__difference__graphs">
              {graphs.map((item) => {
                const { title, image, middle, aspectRatio } = item;
                return (
                  <Graph
                    aspectRatio={aspectRatio}
                    middle={middle}
                    lowKey={title}
                    title={title}
                    image={formattedImages[image]}
                  />
                );
              })}
            </div>
          </div>
        </section>
        <section id="cs-david" className="cp-homepage__david">
          <div className="cp-homepage__david__inner">
            <div className="cp-homepage__david__header">
              <h2>Our Team</h2>
            </div>
            <div className="cp-homepage__david__content">
              <div ref={davidImageRef}>
                <TeamCard
                  image={formattedImages.david}
                  data={david}
                  size="large"
                />
              </div>
              <div className="cp-homepage__david__content__text">
                <p>{david.description}</p>
                <p>{david.description2}</p>
              </div>
            </div>
          </div>
        </section>
        <section id="cs-timeline" className="cp-homepage__timeline">
          <div className="cp-homepage__timeline__inner">
            <h2>Our Timeline</h2>
            <Timeline />
          </div>
        </section>
        <section id="cs-team" className="cp-homepage__team">
          <div ref={teamImagesRef} className="cp-homepage__team__inner">
            {employees.map((teamMember) => {
              return (
                <div
                  key={teamMember.name}
                  className="cp-homepage__team__member"
                >
                  <TeamCard
                    image={formattedImages[teamMember.image]}
                    data={teamMember}
                    size="medium"
                  />
                  <p>{teamMember.description}</p>
                </div>
              );
            })}
          </div>
        </section>
        <section id="cs-clients" className="cp-homepage__clients">
          <div className="cp-homepage__clients__inner">
            <div className="cp-homepage__clients__header">
              <h2>Our Clients</h2>
              <p>
                We are happy to provide a list of representative clients and
                references to potential clients. However, given the high
                profiles of our clients and our strict confidentiality
                standards, Claypoole does not identify specific clients on the
                web.
              </p>
            </div>
            <div
              ref={clientImagesRef}
              className="cp-homepage__clients__card-index"
            >
              <ClientCard
                data={clients[0]}
                image={formattedImages[clients[0].quote[0].image]}
              />
              <ClientCard data={clients[1]} />
            </div>
          </div>
        </section>
        <section id="cs-candidates" className="cp-homepage__candidates">
          <div className="cp-homepage__candidates__inner">
            <div className="cp-homepage__candidates__header">
              <h2>Our Candidates</h2>
              <p>
                Claypoole hires the most exclusive in-house legal roles for
                elite, name-brand managers. For this reason, we work exclusively
                with top partnership-track associates in good standing at AmLaw
                100 firms who have outstanding academic records.
              </p>
            </div>
            <div
              ref={candidatesImagesRef}
              className="cp-homepage__candidates__index"
            >
              {candidates.map((item) => {
                return (
                  <ClientCard
                    key={item.title}
                    data={item}
                    image={formattedImages[item.quote[0].image]}
                  />
                );
              })}
            </div>
            <p>
              <strong>Notice to candidates: </strong>Because we only engage
              candidates who we believe to be exceptional, we are hesitant to
              take unsolicited resumes, but encourage those who fit the above
              description to reach out at{" "}
              <Link to="mailto:recruiting@claypoolesearch.com">
                recruiting@claypoolesearch.com
              </Link>
            </p>
          </div>
        </section>
        <QuoteSection quotes={quotes2} />
      </div>
      <ProgressBar />
    </>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg|png|svg)/" }
        relativeDirectory: { eq: "common" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default React.memo(Home);
